<template>
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">
                {{ title }}
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to texts management"
              @click="$router.push({name: 'admin.section-labels.index'})"
            ><i data-feather="chevron-left" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <div class="card card--table">
              <div class="card-header d-flex align-items-center justify-content-between">
                <div>
                  <h4><strong>{{ dataTotal }}</strong> results</h4>
                </div>
                <div>
                  <span
                    class="dis-ib"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Open filters"
                  ><a
                    class="btn btn-icon btn-icon rounded-circle btn-flat-secondary"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasFilters"
                    aria-controls="offcanvasFilters"
                    @click="openFilters"
                  ><i data-feather="filter" /></a></span>
                </div>
              </div>
              <div
                v-if="filters"
                id="usedFilters"
                class="card-body"
              >
                <template v-if="Object.keys(watchFilters).filter(e => e != 'undefined').length > 0">
                  Applied filters:
                  <span
                    v-for="filter, index in watchFilters"
                    :key="index"
                    class="badge badge-light-dark ms-1 mb-1"
                  >
                    <template v-if="Array.isArray(filter)">
                      <template
                        v-for="str in filter"
                      >
                        {{ str.name }} is array
                        <a
                          :key="str.name"
                          class="ms-1 text-black"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Delete filter"
                          @click="deleteFilter(index)"
                        ><i data-feather="x" /></a>
                      </template>
                    </template>
                    <template v-else-if="typeof filter === 'object' && filter && filter.name != 'All users'">
                      {{ filter.name.startsWith('Categories') ? capitalizeFirstLetter(filter.name.split('_').join(' ')) : filter.name }}
                      <a
                        class="ms-1 text-black"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Delete filter"
                        @click="deleteFilter(index)"
                      ><i data-feather="x" /></a>
                    </template>
                    <template v-else-if="(filter && (typeof filter !== 'object'))">
                      {{ filter }}
                      <a
                        class="ms-1 text-black"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Delete filter"
                        @click="deleteFilter(index)"
                      ><i data-feather="x" /></a>
                    </template>
                  </span>
                </template>
              </div>
            </div>
            <div v-if="labels && hasFilters">
              <CardLabelEdit
                v-for="label in auxLabels"
                :key="label.id"
                :label="label"
                @onSaveButon="saveData($event)"
              />
            </div>
            <div v-else-if="!hasFilters">
              <div
                class="alert alert-warning"
              >
                <div class="alert-body">
                  <p>Filter to show data</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ItemInfoSheet :table-id="filterInfoSheet">
          <template #info-sheet-title>
            Search & Filters
          </template>
          <template
            #info-sheet
          >
            <div class="offcanvas-body">
              <div class="mb-1">
                <label
                  for="name"
                  class="form-label required"
                >Name</label>
                <input
                  v-model="filters.name"
                  type="text"
                  class="form-control"
                  name="name"
                  @change="saveFilter({ customLabel: true, name: `Name: ${$event.target.value}`, value: `${$event.target.value}` }, 'name')"
                >
              </div>
              <hr>
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >Menu</label>
                <v-select
                  v-model="filters.menu"
                  label="menu_format"
                  :options="menus"
                  :get-option-key="option => option.menu"
                  @input="saveFilter({ customLabel: true, name: `Menu: ${$event ? $event.name : ''}`, value: $event }, 'menu')"
                />
              </div>
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >Categories</label>
                <v-select
                  v-model="filters.categories"
                  label="section_format"
                  multiple
                  :options="categories"
                  :get-option-key="option => option.section"
                  @input="saveFilter({ customLabel: true, name: `Categories: ${$event ? ($event.map(e => e.section)).join(' - ') : ''}`, value: $event }, 'categories')"
                />
              </div>
            </div>
            <div class="offcanvas-footer mt-auto">
              <button
                type="button"
                class="btn btn-dark mb-1 d-grid w-100"
                @click="applyFilters"
              >
                Apply
              </button>
            </div>
          </template>
        </ItemInfoSheet>

      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import ItemInfoSheet from '@/views/back/partials/offcanvas/ItemInfoSheet.vue'
import vSelect from 'vue-select'
import CardLabelEdit from './components/CardLabelEdit.vue'

export default {
  components: {
    ItemInfoSheet,
    CardLabelEdit,
    vSelect,
  },
  data() {
    return {
      title: 'Search result',
      results: 0,
      filterInfoSheet: 1,
      auxLabels: [],
    }
  },
  computed: {
    ...mapGetters({
      categories: 'sectionLabels/itemCategories',
      menus: 'sectionLabels/itemMenus',
      loggedUser: 'auth/admin',
      labels: 'sectionLabels/item',
      dataTotal: 'sectionLabels/itemTotal',
      filters: 'filters/filters',
      watchFilters: 'filters/watchFilters',
    }),
    hasFilters() {
      if (Object.keys(this.filters ?? {}).length) {
        if (Array.isArray(this.filters.categories) && this.filters.categories.length) {
          return true
        }
        if (this.filters.name && this.filters.name !== '') {
          return true
        }
        if (Object.keys(this.filters.menu ?? {})) {
          return true
        }
        return false
      }
      return false
    },
  },
  watch: {
    labels() {
      this.auxLabels = Object.keys(this.labels).map(key => this.labels[key])
    },
  },
  async mounted() {
    await this.$store.dispatch('sectionLabels/fetchCategories')
    await this.$store.dispatch('sectionLabels/fetchMenus')
    if (this.hasFilters) {
      await this.$store.dispatch('sectionLabels/filterSearch', this.$store.getters['filters/filters'])
    } else {
      await this.$store.dispatch('sectionLabels/clearItem')
      this.$store.dispatch('modals/toggleInfoItemSheet', true)
      this.$store.dispatch('modals/toggleTableClicked', this.filterInfoSheet)
    }

    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    openFilters() {
      this.$store.dispatch('modals/toggleInfoItemSheet', true)
      this.$store.dispatch('modals/toggleTableClicked', this.filterInfoSheet)

      setTimeout(() => {
        // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
    async applyFilters() {
      this.$store.dispatch('modals/toggleTableClicked', this.filterInfoSheet)
      this.$store.dispatch('modals/toggleInfoItemSheet', false)

      if (this.hasFilters) {
        await this.$store.dispatch('sectionLabels/filterSearch', this.filters)
      } else {
        await this.$store.dispatch('sectionLabels/clearItem')
      }

      setTimeout(() => {
        // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
    async saveFilter(value, field) {
      if (field === 'menu') {
        await this.$store.dispatch('sectionLabels/fetchCategories', value.value.menu)
      }

      if (field === 'categories' && Array.isArray(value) && !value.length) {
        return
      }

      if (!value || (!value.value || (value.value === 'null' || (Array.isArray(value.value) && !value.value.length)))) {
        this.$store.dispatch('filters/deleteFilters', field)
      } else {
        this.$store.dispatch('filters/saveFilters', {
          value,
          field,
        })
      }
    },
    async deleteFilter(index) {
      let checkMenu = false
      if (this.filters.menu) {
        checkMenu = true
      }
      await this.$store.dispatch('filters/deleteFilters', index)
      if (!this.filters.menu && checkMenu) {
        await this.$store.dispatch('sectionLabels/fetchCategories')
      }

      if (this.hasFilters) {
        await this.$store.dispatch('sectionLabels/filterSearch', this.filters)
      } else {
        await this.$store.dispatch('sectionLabels/clearItem')
      }

      setTimeout(() => {
        // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 250)
    },
    async saveData(label) {
      try {
        await this.$store.dispatch('sectionLabels/updateItem', {
          data: label,
        })
        await this.$store.dispatch('sectionLabels/filterSearch', this.filters)
        this.$toast.success('Saved data')
      } catch (e) {
        // this.errors = e.response.data.message
        Vue.swal(`Error on save label! ${e.response.data.message}`, '', 'error')
      }
    },
    capitalizeFirstLetter(str) {
      const s = str.split('')
      s[12] = s[12].toUpperCase()
      return s.join('')
    },
  },
}
</script>
