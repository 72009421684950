<template>
  <div
    class="card card__text__result"
  >
    <div
      class="card-body"
    >
      <div
        class="row"
      >
        <div class="col-sm-11">
          <p class="text-muted">
            <small>{{ label.menu_format }} - {{ label.section_format }} / {{ label.name_format }}</small>
          </p>
          <p>{{ valueLabel }}</p>
        </div>
        <div class="col-sm-1 card__text__result--edit">
          <a
            class="btn btn-icon btn-dark"
            @click="modeUpdate = !modeUpdate"
          ><i data-feather="edit-3" /></a>
        </div>
      </div>
      <div
        v-if="modeUpdate"
        class="row"
      >
        <div class="col">
          <div class="mb-1">
            <input
              v-model="label.value"
              type="text"
              class="form-control"
            >
          </div>
          <a
            class="btn btn-sm btn-success"
            @click="save"
          >Save</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modeUpdate: false,
      valueLabel: this.label.value,
    }
  },
  watch: {
    label() {
      this.valueLabel = this.label.value
    },
  },
  mounted() {
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 200)
  },
  methods: {
    save() {
      this.$emit('onSaveButon', this.label)
      this.modeUpdate = false
    },
  },
}
</script>

<style>

</style>
